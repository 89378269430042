<template>
  <aside>
    <header>
      <h2>Top-up Points</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main>
      <p>Instantly top-up your team's point balance.</p>

      <div class="top-up-form" v-if="canTopUp">
        <div class="input-wrap">
          <div class="input-elem" :class="{'error': $v.points.$error}">
            <span class="point-input" role="textbox" contenteditable @input="handlePointInput" ref="point_field"></span>
            <label>points</label>
          </div>
        </div>

        <ul class="transaction-details" v-if="payment_intent !== null">

          <li>
            <label>Pay with</label>
            <span>
              <div v-if="payment_method !== null" class="selected-card">
                <svg viewBox="0 0 128 82" class="card-icon"><use :xlink:href="`#${payment_method.card.brand}-icon`"></use></svg>
                <div>
                  <div>x{{payment_method.card.last4}} <small>({{payment_method.card.exp_month}}/{{payment_method.card.exp_year}})</small></div>
                </div>
              </div>
              <a href="#" v-else @click.prevent="addPaymentMethod">Add a card</a>
            </span>
          </li>


          <li>
            <label>Point cost</label>
            <span>
              {{$formatCurrency(point_cost)}}
            </span>
          </li>

          <li>
            <label>Platform fee</label>
            <span>
              {{$formatCurrency(topup_fee)}}
            </span>
          </li>


        </ul>
      </div>

      <div class="top-up-form" v-else>
        <p><strong>Welcome!</strong> Before you can purchase points, we need to verify your account. Please reach out to help@hithrive.com.</p>
      </div>
    </main>

    <footer>
      <button class="btn btn-sm btn-green" :disabled="payment_method === null || payment_intent === null" :class="{'loading': loading}" @click="attemptTopup">Purchase points for <strong>{{$formatCurrency(point_cost+topup_fee)}}</strong></button>
    </footer>
  </aside>
</template>

<script>
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import {debounce} from 'lodash';

export default {
  computed: {
    point_cost() {
      return this.points / 100;
    },
    topup_fee() {
      return (this.points * 0.1) / 100;
    },
    team() {
      return this.$store.state.team;
    },
    canTopUp() {
      return this.$store.state.team.metadata.rewards_approved === true;
    }
  },
  data() {
    return {
      points: '',
      loading: false,
      payment_intent: null,
      payment_method: null
    }
  },
  watch: {
    points(val) {
      if(this.$v.$error) {
        this.payment_intent = null;
        return;
      }

      this.updatePaymentIntent();
    }
  },
  validations: {
    points: {
      required,
      minValue: minValue(100),
      maxValue: maxValue(999999)
    }
  },
  created() {
    this.populatePaymentMethod();
  },
  methods: {
    async attemptTopup() {
      this.loading = true;

      const {paymentIntent, error} = await this.$stripe.confirmCardPayment(
        this.payment_intent.client_secret,
        {
          payment_method: this.payment_method.id
        }
      );

      if(error) {
        this.loading = false;
        this.$toast.error(
          error.message
        )
        return;
      }

      //success
      this.$toast.success('Your point top-up was successful.')

      this.loading = false;

      // this is technically the currency amount in cents, will need to change we accept anything other than USD
      this.$store.dispatch('updateTeam', {purchased_points: this.team.purchased_points + parseInt(this.points)});

      this.$emit('done', 'TOPUP_SUCCESS', paymentIntent.id);
    },
    addPaymentMethod() {
      this.$root.$emit(
        'openModal',
        'Billing/SetPaymentMethod',
        {},
        (result) => {
          if(result === 'PAYMENT_METHOD_SAVED') {
            this.populatePaymentMethod();
          }
        }
      )
    },
    async populatePaymentMethod() {
      const resp = await this.$api.Billing.get_payment_method();

      if( resp.sources.length > 0 ) {
        this.payment_method = resp.sources[0];
      }
    },
    updatePaymentIntent: debounce(async function() {
      this.loading = true;
      const resp = await this.$api.Billing.get_topup_intent(this.points, this.payment_intent ? this.payment_intent.id : null)
      this.payment_intent = resp;
      this.loading = false;
    }, 250),
    close() {
      this.$emit('done');
    },
    handlePointInput($e) {
      const val = $e.target.innerText;
      if(parseInt(val) === 0) this.$refs.point_field.innerText = '';

      this.$v.points.$model = $e.target.innerText;
    }
  }
}
</script>

<style lang="scss" scoped>
.top-up-form {
  background: #fff;
  box-shadow: rgba(0,0,0,0.05) 0 5px 10px;
  border: 1px solid rgba(0,0,0,0.05);
  border-radius: 10px;
  padding: 25px;
  max-width: 380px;
  min-height: 50px;

  .selected-card {
    display: flex;
    align-items: flex-start;

    > svg {
      flex: 0 0 38px;
      border-radius: 4px;
      margin: 0 10px 0 0;
    }

    > div {
      > div {
        font-size: 13px;
        padding: 0 0 0;
        line-height: 12px;
        > small {
          color: $muted-text;
        }
      }
    }
  }

  .transaction-details {
    padding: 0;
    margin: 25px 0 0;
    display: block;

    > li {
      display: flex;
      padding: 5px 0;

      > label {
        display: block;
        flex: 0 0 30%;
        color: $muted-text;
        margin: 0;
      }

      > span {
        display: block;
        flex: 0 0 auto;
        margin: 0 0 0 auto;
      }
    }
  }

  .input-wrap {
    display: block;

    > .input-elem {
      position: relative;
      display: table;
      margin: 0 auto;

      > label {
        display: block;
        margin: 0;
        font-size: 20px;
        position: absolute;
        right: 0;
        bottom: 10px;
        pointer-events: none;
      }

      > span {
        display: block;
        font-size: 32px;
        outline: none;
        padding: 3px 60px 3px 5px;
        min-width: 50px;

        &:empty {
          &:after {
            content: '0';
            pointer-events: none;
            color: rgba($muted-text, 0.5);
          }
        }
      }

      &.error {
        > label {
          color: $red;
        }

        > span {
          color: $red;
        }
      }
    }
  }
}
</style>